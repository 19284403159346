import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds.`}</p>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/components/text-input"
      }}>{`text input`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/dropdown"
      }}>{`dropdown`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/checkbox"
      }}>{`checkbox`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/radio-button"
      }}>{`radio button`}</a>{`, `}<a parentName="p" {...{
        "href": "/component/toggle"
      }}>{`toggle`}</a>{`, and `}<a parentName="p" {...{
        "href": "/component/button"
      }}>{`button`}</a>{` pages for specific typography details on each component.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "38.04347826086957%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of form fields in the two colors",
        "title": "Example of form fields in the two colors",
        "src": "/static/89c118d135a93abadda7f80759d34c03/fb070/form-style-3.png",
        "srcSet": ["/static/89c118d135a93abadda7f80759d34c03/d6747/form-style-3.png 288w", "/static/89c118d135a93abadda7f80759d34c03/09548/form-style-3.png 576w", "/static/89c118d135a93abadda7f80759d34c03/fb070/form-style-3.png 1152w", "/static/89c118d135a93abadda7f80759d34c03/c3e47/form-style-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Form headings and labels should be set in sentence case with the first letter of the first word capitalized. Refer to the `}<a parentName="p" {...{
        "href": "/components/text-input"
      }}>{`text input`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/dropdown"
      }}>{`dropdown`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/checkbox"
      }}>{`checkbox`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/radio-button"
      }}>{`radio button`}</a>{`, `}<a parentName="p" {...{
        "href": "/component/toggle"
      }}>{`toggle`}</a>{`, and `}<a parentName="p" {...{
        "href": "/component/button"
      }}>{`button`}</a>{` pages for specific typography details on each component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28 / 1.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input::placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form__helper-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$helper-text-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`Forms are made up of several different components. The order in which these elements are arranged is flexible, but should always follow the spacing guidelines below.`}</p>
    <p>{`Forms can be one column or two. The width of each column varies based on the content and layout of the design. On mobile, forms can only have one column.`}</p>
    <p>{`All icons can be found on the `}<a parentName="p" {...{
        "href": "/guidelines/iconography/library"
      }}>{`iconography`}</a>{` library page.`}</p>
    <h3 {...{
      "id": "recommended"
    }}>{`Recommended`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Element`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 / 1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-06`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title area`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gutter between items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Buttons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "83.48214285714286%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structure and spacing measurements for a double column form ",
        "title": "Structure and spacing measurements for a double column form ",
        "src": "/static/c84ae7e1e1ffc3b8461009d973b28a90/fb070/form-style-2.png",
        "srcSet": ["/static/c84ae7e1e1ffc3b8461009d973b28a90/d6747/form-style-2.png 288w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/09548/form-style-2.png 576w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/fb070/form-style-2.png 1152w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/fb104/form-style-2.png 1728w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/8fefe/form-style-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "76.16071428571428%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structure and spacing measurements for a single column form",
        "title": "Structure and spacing measurements for a single column form",
        "src": "/static/a6c6128e315b1044f2a79981b3a852c2/fb070/form-style-1.png",
        "srcSet": ["/static/a6c6128e315b1044f2a79981b3a852c2/d6747/form-style-1.png 288w", "/static/a6c6128e315b1044f2a79981b3a852c2/09548/form-style-1.png 576w", "/static/a6c6128e315b1044f2a79981b3a852c2/fb070/form-style-1.png 1152w", "/static/a6c6128e315b1044f2a79981b3a852c2/fb104/form-style-1.png 1728w", "/static/a6c6128e315b1044f2a79981b3a852c2/8fefe/form-style-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      